import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import history from '@/history';
import { getUserPermsSync } from '@/rbac';
import actions from '@routes/actions';
import routeMetadata from '@routes/routeMetadata';
import { getAccountContextForHistory } from '@routes/utils';

import s from './Base.scss';

// Get all the named base path items from the route metadata
const basePathItems = [
  ...new Set(
    routeMetadata
      .filter(item => item.path !== '(.*)' && item.path !== '')
      .map(item => item.path.split('/')[1])
      .filter(Boolean),
  ),
];

const Base = props => {
  const { auth, children, redirect } = props;
  const { isAuthenticated, account } = auth;

  const hasSwitchedContext =
    account?.context?.accountname !== account.accountname;

  const context = getAccountContextForHistory(account.context);

  // Obtain the base path.
  // If a user logs in with something like `/system` as their URL we need to
  // send them to that path with their current context. But not if that isn't a
  // legitimate component path, in which case we fall through the first
  // condition into our standard redirection map.
  const pathnameArr = props.pathname.split('/');
  const basePath = pathnameArr[1];

  useEffect(() => {
    if (isAuthenticated) {
      if (
        basePath &&
        basePath !== context &&
        pathnameArr.length >= 2 &&
        !account.accounts.some(item => item.name === basePath) &&
        basePathItems.includes(basePath)
      ) {
        history.replace(`${context}${props.pathname}`, {});
        return;
      }

      const primaryPerms = getUserPermsSync({
        account,
        actions: actions[redirect.primary.path],
      });

      const secondaryPerms = getUserPermsSync({
        account,
        actions: actions[redirect.secondary.path],
      });

      history.replace(
        primaryPerms && redirect.primary.perms.every(perm => primaryPerms[perm])
          ? `${context}/${redirect.primary.path}`
          : secondaryPerms &&
              redirect.secondary.perms.every(perm => secondaryPerms[perm])
            ? `${context}/${redirect.secondary.path}`
            : `${context}/${redirect.tertiary.path}`,
        {},
      );
    }
  }, [isAuthenticated, hasSwitchedContext]);

  return (
    <div className={s.root}>
      {auth && auth.isAuthenticated ? (
        <Dimmer active className={s.pageDimmer}>
          <Loader size="huge" active inline="centered" content="Loading" />
        </Dimmer>
      ) : (
        children
      )}
    </div>
  );
};

export const propTypes = {
  pathname: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  redirect: PropTypes.shape({
    primary: PropTypes.shape({
      path: PropTypes.string,
      perms: PropTypes.arrayOf(PropTypes.string),
    }),
    secondary: PropTypes.shape({
      path: PropTypes.string,
      perms: PropTypes.arrayOf(PropTypes.string),
    }),
    tertiary: PropTypes.shape({
      path: PropTypes.string,
      perms: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  auth: PropTypes.shape({
    prevAuthState: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    account: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        accountname: PropTypes.string,
        isActive: PropTypes.bool,
        isAdmin: PropTypes.bool,
        sessionID: PropTypes.string,
        sessionStart: PropTypes.string,
        userCreatedAt: PropTypes.string,
        userCreatedBy: PropTypes.string,
        userLastUpdated: PropTypes.string,
        username: PropTypes.string,
      }),
    ]),
  }).isRequired,
};

Base.propTypes = propTypes;

export default withStyles(s)(Base);
